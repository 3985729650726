import { IImage } from '@models/common-models/image';

export interface IBanner {
  id?: number;
  title: string;
  subtitle: string;
  title_json?: { [key: string]: string };
  subtitle_json?: { [key: string]: string };
  button_text_json?: { [key: string]: string };
  visible: number;
  img_id?: string;
  video_url?: string;
  video_id?: string;
  autoplay?: number;
  controls?: number;
  url: string;
  type: string;
  archived?: number;
  order_by?: number;
  created_at?: string;
  updated_at?: string;
  images?: IImage[];
  img_url?: string;
  image?: File;
  background_color?: string;
  text_color?: string;
  custom_url?: string;
  image_position: string;
  in_new_tab: boolean;
  button_text: { [key: string]: string };
  mute: boolean
}
