export interface IEmailTemplateOrderConfirmation {
    email_subject: string;
    order_detail: string;
    order_number: string;
    table_ref_number: string;
    table_image: string;
    table_price_each: string;
    table_quantity: string;
    table_subtotal: string;
    date_time: string;
    subtotal: string;
    contact_information: string;
    shipping: string;
    name: string;
    tax_vat: string;
    company_name: string;
    total: string;
    phone: string;
    payment_method: string;
    email: string;
    payment_reference: string;
    order_confirmation: string;
    shipping_address: string;
    billing_address: string;
    email_has_been_sent_phrase: string;
    phrase: string;
}
