export interface IColoursSetting {
    list_view_box_colour: string;
    colour_background_header: string;
    colour_text_header: string;
    colour_background_menu: string;
    colour_text_menu: string;
    colour_text_menu_hover: string;
    colour_background_body: string;
    colour_text_title: string;
    colour_text_body: string;
    colour_text_body_hover: string;
    colour_button_normal: string;
    colour_button_normal_text: string;
    colour_button_hover: string;
    colour_button_hover_text: string;
    colour_button_pressed: string;
    colour_button_pressed_text: string;
    colour_background_footer: string;
    colour_text_footer: string;
    colour_text_footer_hover: string;
    colour_background_subscription: string;
}
