export const enum ValidationRegExps {
    VALID_URL = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
    ONLY_NUMBER = '^[0-9]*$',
}

export const ONLY_NUMBER_VALIDATION = new RegExp('^[0-9 .]*$');
export const PHONE_VALIDATOR = new RegExp(/^([0-9\s-+()]*)$/);
export const VALID_URL_REGEXP = new RegExp('(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
export const GENERAL_PAGE_URL_VALIDATOR = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
export const VIMEO_VIDEO_ID_REGEXP = /https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
export const YOUTUBE_VIDEO_ID_REGEXP =
/(?:http(?:s)?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user|shorts)\/))([^\?&\"'<> #]+)/;
export const VALID_HEX_COLOR = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');
export const VALID_RGBA_COLOR = new RegExp(/^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d*(?:\.\d+)?)\)$/);
