export interface IArtistType {
    archived?: number;
    artist_count?: number;
    id?: number;
    sync_key?: string;
    sync_last?: string;
    type: { [key: string]: string } | string;
    type_detail: { [key: string]: string } | string;
    type_list: { [key: string]: string } | string;
    type_json: { [key: string]: string };
    type_detail_json: { [key: string]: string };
    type_list_json: { [key: string]: string };
}
