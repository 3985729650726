export interface ITextChartData {
    firstItem: {
        label: string;
        value: number;
    };
    secondItem: {
        label: string;
        value: number;
    }
}
