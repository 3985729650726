export interface ISalesAnalytics {
  enquires: ISalesAnalyticsItem | number;
  unread_enquires: ISalesAnalyticsItem | number;
}

interface ISalesAnalyticsItem {
  daily: number;
  last_month: number;
  last_week: number;
  last_year: number;
  monthly: number;
  total: number;
  weekly: number;
  yearly: number;
  yesterday: number;
}
