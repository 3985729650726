export interface IEmailTemplateTradeRequestDealer {
  subject: string;
  header_text: string;
  date_and_time_phrase: string;
  name_phrase: string;
  mobile_phrase: string;
  phone_phrase: string;
  company_phrase: string;
  email_address_phrase: string;
  accept_phrase: string;
  reject_phrase: string;
  email_has_been_sent_phrase: string;
}
