import { EStockLocationHistoryStatus } from '@enums/stock-location-history.enum';

export interface IStockLocationHistory {
    stock_location_id?: string; // unique id of the history item
    address_id?: string;
    archived?: number;
    contact_id?: string;
    created_by?: string;
    internal_name?: string;
    show_due_days?: boolean;
    created_datetime: string;
    date_delivery?: string;
    date_return_due?: string;
    date_returned?: string;
    location_id: string;
    notes?: { [key: string]: string };
    shipper_id?: string;
    shipping_method?: string;
    status?: EStockLocationHistoryStatus;
    stock_id: string;
    stock_location_type?: string;
    sync_key?: string;
    sync_last?: string;
    value_insurance?: string;
    value_item?: string;
    notes_json?: { [key: string]: string };
    days_due?: number;
}
