export interface IWebsiteLanguage {
    default_language: number;
    language_id: string;
    name: string;
    sync_key: string;
    sync_last: string;
    tag_close: string;
    tag_open: string;
    active: number;
    url: string;
    code: string;
}
