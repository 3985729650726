export interface IStockCategoryAnalytics {
  available: number;
  sold: number;
  total: number;
  category_name: string;
  children: IStockCategoryAnalyticsChildren[];
}

export interface IStockCategoryAnalyticsChildren {
  available: number;
  sold: number;
  total: string;
  category_name: string;
}
