export interface ISettingsCategoryItem {
    category_name_json?: { [key: string]: string };
    category_description_json?: { [key: string]: string };
    category_keywords_json?: { [key: string]: string };
    category_name: { [key: string]: string } | string;
    category_description: { [key: string]: string };
    category_keywords: { [key: string]: string };
    category_image?: string;
    parent_category?: string;
    archived?: string;
    category_status?: string;
    is_disabled?: boolean;
    all_items_count?: number;
    vat_id: string;
    stocks_count?: number;
    children?: ISettingsCategoryItem[];
    category_id?: string;
    sort_order?: number;
    total_stock?: number;
    count_in_stock?: number;
    text_color: string;
    visible: number;
    text_hover_color: string;
    text_active_color: string;
}
