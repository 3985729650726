export interface IGoogleFormType {
  google_key_analytics: { [key: string]: string };
  google_key_analytics_type: string;
  google_key_maps: string;
  google_key_translate: string;
  google_key_recaptcha_public: string;
  google_key_recaptcha_secret: string;
}

export interface IGoogleKeys {
  google_key_analytics: { [key: string]: string };
  google_key_analytics_type: string;
  google_key_maps: string;
  google_key_translate: string;
  google_key_recaptcha_public: string;
  google_key_recaptcha_secret: string;
}
