export interface IOrderDetail {
  order_uuid: string;
  order_number: number;
  contact_uuid: string;
  contact_vat_number: string;
  status_id: number;
  billing_address_1: string;
  billing_address_2: string;
  billing_address_3: string;
  billing_town: string;
  billing_city: string;
  billing_state: string;
  billing_country: string;
  billing_zip: string;
  ship_same_address: number;
  shipping_address_1: string;
  shipping_address_2: string;
  shipping_address_3: string;
  shipping_town: string;
  shipping_city: string;
  shipping_state: string;
  shipping_country: string;
  shipping_zip: string;
  shipping_note: string;
  order_subtotal: number;
  order_item_count: number;
  order_vat: number;
  order_shipping_total: number;
  order_total: number;
  order_voucher_code: string;
  order_voucher_id: string;
  order_voucher_details: string;
  order_voucher_amount: number;
  option_customer_to_collect: number;
  payment_type: string;
  payment_transaction: {
    id: string;
    object: string;
    api_version: string;
    created: number;
    data: {
      object: {
        id: string;
        object: string;
        amount: number;
        amount_captured: number;
        amount_refunded: number;
        application: string;
        application_fee: string;
        application_fee_amount: number;
        balance_transaction: string;
        billing_details: {
          address: {
            city: string;
            country: string;
            line1: string;
            line2: string;
            postal_code: number;
            state: string;
          };
          email: string;
          name: string;
          phone: string;
        };
        calculated_statement_descriptor: string;
        captured: boolean;
        created: number;
        currency: string;
        customer: string;
        description: string;
        destination: string;
        dispute: number;
        disputed: boolean;
        failure_code: number;
        failure_message: string;
        fraud_details: [

        ];
        invoice: string;
        livemode: boolean;
        metadata: {
          payment_date: string;
          order_uuid: string;
          request_url: string;
          web: string;
          url: string;
          company_id: string;
          type: string;
          template: string;
          site_url: string;
          contact: string;
          profile: string;
          success_url: string;
          error_url: string;
        };
        on_behalf_of: string;
        order: string;
        outcome: {
          network_status: string;
          reason: string;
          risk_level: string;
          risk_score: number;
          seller_message: string;
          type: string;
        };
        paid: boolean;
        payment_intent: string;
        payment_method: string;
        payment_method_details: {
          card: {
            brand: string;
            checks: {
              address_line1_check: string;
              address_postal_code_check: string;
              cvc_check: string;
            };
            country: string;
            exp_month: number;
            exp_year: number;
            fingerprint: string;
            funding: string;
            installments: string;
            last4: string;
            network: string;
            three_d_secure: string;
            wallet: string;
          };
          type: string;
        };
        receipt_email: string;
        receipt_number: number;
        receipt_url: string;
        refunded: boolean;
        refunds: {
          object: string;
          has_more: boolean;
          total_count: number;
          url: string;
        };
        review: string;
        shipping: string;
        source: string;
        source_transfer: string;
        statement_descriptor: string;
        statement_descriptor_suffix: string;
        status: string;
        transfer_data: string;
        transfer_group: string;
      }
    };
    livemode: boolean;
    pending_webhooks: number;
    request: {
      id: string;
      idempotency_key: string;
    };
    type: string;
  };
  status: string;
  shipping_url: string;
  timestamp: string;
  json_obj: {
    order_uuid: string;
    contact_uuid: string;
    contact_vat_number: string;
    status_id: string;
    billing_address_1: string;
    billing_address_2: string;
    billing_address_3: string;
    billing_town: string;
    billing_city: string;
    billing_state: string;
    billing_country: string;
    billing_zip: string;
    ship_same_address: string;
    shipping_address_1: string;
    shipping_address_2: string;
    shipping_address_3: string;
    shipping_town: string;
    shipping_city: string;
    shipping_state: string;
    shipping_country: string;
    shipping_zip: string;
    shipping_note: string;
    order_subtotal: number;
    order_item_count: number;
    order_vat: string;
    order_shipping_total: number;
    order_total: number;
    order_voucher_code: string;
    order_voucher_id: string;
    order_voucher_details: string;
    order_voucher_amount: number;
    option_customer_to_collect: string;
    payment_type: string;
    payment_transaction: string;
    timestamp: string;
    contact_source_id: string;
    sync_key: string;
    sync_last: string;
    seller: {
      country: string;
      vat_tax_status: string;
      vat_eu_margin_scheme: string;
      shipping_multiple_items: string;
      shipping_free_min_order_value_international: string;
      shipping_free_min_order_value_home: string;
      shipping_same_address_min_order_value: string;
      maximum_value_card_sales: string;
      licence_expiry_date: string;
      currency_sign: string;
    };
    contact: {
      contact_uuid: string;
      archived: string;
      title: string;
      firstname: string;
      lastname: string;
      company_name: string;
      ship_same_address: string;
      shipping_address_id: string;
      billing_address_id: string;
      phone: string;
      mobile: string;
      email: string;
      type: string;
      opt_in_email: string;
      source: string;
      timestamp_created: string;
      vat_number: string;
      vat_reg_metadata: string;
      vat_reg_validate_date: string;
      vat_country: string;
      vat_address: string;
      vat_company: string;
      newsletter_subscribe: string;
      sync_key: string;
      sync_last: string;
    };
    items:
    {
      order_item_uuid: string;
      order_uuid: string;
      status_id: string;
      stock_id: string;
      quantity: string;
      price: string;
      item_vat_type: string;
      vat_amount: number;
      shipping_amount: string;
      discount_amount: number;
      order_num: string;
      create_datetime: string;
      total: number;
      sync_key: string;
      sync_last: string;
      vat_id: string;
      vat_rate: string;
      vat_type: string;
      title: string;
      permalink: string;
      shipping_class_id: string;
      reserved: string;
      stock_number: string;
      current_quantity: string;
      list_url: string;
      thumb_url: string;
      type_list: string;
      type_detail: string;
      name_first: string;
      name_last: string;
      artist_permalink: string;
      gross_total: number;
      net_total: number;
      shipping_region: string;
      tRegionColName: string;
      shipping_poa: boolean;
    }[];
    lowest_price: number;
    highest_price: number;
    gross_total: number;
    shipping_free: boolean;
    apply_vat: number;
    message_vat: string;
    eu: string;
    shipping_region: string;
    calculated_shipping_total: number;
    item_update:
    {
      result: number;
      sql: string;
    }[]
  };
  contact_source_id: string;
  sync_key: string;
  sync_last: string;
  shipping_info: {
    address_uuid: string;
    archived: number;
    contact_uuid: string;
    address_reference: string;
    address_1: string;
    address_2: string;
    address_3: string;
    town: string;
    city: string;
    state: string;
    zip: string;
    country_code: string;
    note: string;
    company_name: string;
    contact_name: string;
    contact_phone: string;
    timestamp: string;
    sync_key: string;
    sync_last: string;
  };
  billing_info: {
    address_uuid: string;
    archived: number;
    contact_uuid: string;
    address_reference: string;
    address_1: string;
    address_2: string;
    address_3: string;
    town: string;
    city: string;
    state: string;
    zip: string;
    country_code: string;
    note: string;
    company_name: string;
    contact_name: string;
    contact_phone: string;
    timestamp: string;
    sync_key: string;
    sync_last: string;
  };
  voucher: {
    voucher_type: string;
  };

}
