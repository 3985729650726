import { IMatterPort } from "@models/utils/matterport";

export interface ICustomPagesResponse {
  title: string;
  title_json: { [key: string]: string };
  content: string;
  content_json: { [key: string]: any };
  show: number;
  type: string;
  matterport_links: IMatterPort[];
}

export interface ICustomPages {
  title: string | { [key: string]: string };
  content: any;
  type: string;
  show?: string;
  update_on_menu: number;
  matterport_ids?: string[];
}
