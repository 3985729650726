import { IEmailTemplateRegistrationUpdate, ShareWishlistUpdate } from '@models/email-templates';
import { IEmailTemplateEnquiryUpdate } from '../enquiry/enquiry-update';
import { IEmailTemplateForgotPasswordUpdate } from '../forgot-password/forgot-password-update';
import { IEmailTemplateOrderConfirmationUpdate } from '../order-confirmation/order-confirmation-update';
import { IEmailTemplateSellToUsUpdate } from '../sell-to-us/sell-to-us-update';
import { IEmailTemplateTradeRequestDelaerUpdate } from '../trade-request-dealer/trade-request-dealer-update';
import { IEmailTemplateTradeRequestUpdate } from '../trade-request/trade_request-update';

export type EmailTemplateUpdate =
    IEmailTemplateRegistrationUpdate |
    ShareWishlistUpdate |
    IEmailTemplateOrderConfirmationUpdate |
    IEmailTemplateForgotPasswordUpdate |
    IEmailTemplateSellToUsUpdate |
    IEmailTemplateTradeRequestDelaerUpdate |
    IEmailTemplateEnquiryUpdate |
    IEmailTemplateTradeRequestUpdate;
