import { IImage } from '@models/common-models/image';
import { IStockItem } from '@models/stock/stock';

export interface ICatalogue {
    archived: number;
    catalog_desc_json: { [key: string]: string };
    catalog_desc?: string;
    catalog_id: string;
    catalog_published_date: string;
    catalog_subtitle_json: { [key: string]: string };
    catalog_subtitle?: string;
    catalog_tag: string;
    catalog_title_json: { [key: string]: string };
    catalog_title?: string;
    catalog_type: string;
    created: string;
    stocks_count: number;
    stocks: IStockItem[];
    primary_image_id: string;
    image: IImage;
    show_artist_name: number;
    sync_key: null;
    sync_last: string;
    visible: number;
    description_json: { [key: string]: string };
    keywords_json: { [key: string]: string };
}
