import { IShippingClassException } from './shipping-class-exception';

export interface IShippingClassItem {
    description: string;
    description_invoice?: string;
    reg00: number;
    reg01: number;
    reg02: number;
    reg03: number;
    reg04: number;
    reg05: number;
    reg06: number;
    reg07: number;
    reg08: number;
    reg09: number;
    shipping_class_id?: string;
    shipping_day: any;
    sync_key?: string;
    sync_last?: string;
    shipping_exceptions?: IShippingClassException[];
}
