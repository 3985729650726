import { EListViewType } from '@enums/index';
import { IListViewWidget } from './list-view';

export interface IListViewResponse {
    is_active: 1 | 0;
    name: string;
    order: string;
    params: IListViewWidget;
    position: string;
    type: EListViewType;
    widgets_uuid: string;
    web_setting_value?: string;
}
