export interface IEnquiries {
      read_status: number;
      enquiry_id: string;
      type: string;
      message: string;
      timestamp: string;
      company_name: string;
      enquiry_code: number;
      firstName: string;
      lastName: string;
}
