export interface IEmailTemplateListItem {
    email_template_id: string;
    email_template_name: string;
    email_template_type: string;
    customized_subject_json: {
        [key: string]: string
    };
    default_subject_json: {
        [key: string]: string
    };
}
