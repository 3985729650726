import { EmailTemplateEnquiryTranslation } from '../enquiry/enquiry-translate';
import { ForgotPasswordTranslation } from '../forgot-password/forgot-password-translation';
import { OrderConfirmationTranslation } from '../order-confirmation/order-confirmation-translate';
import { RegistrationTranslation } from '../registration/registration-translation';
import { SellToUsTranslation } from '../sell-to-us/sell-to-us-translation';
import { ShareItemTranslation } from '../share-item/share-item-translation';
import { ShareWishlistTranslation } from '../share-wishlist/share-wishlist-translation';
import { TradeRequestDelaerTranslation } from '../trade-request-dealer/trade-request-dealer-translation';
import { TradeRequestTranslation } from '../trade-request/trade-request-translation';

export type EmailTemplatesTranslation =
    EmailTemplateEnquiryTranslation |
    ForgotPasswordTranslation |
    OrderConfirmationTranslation |
    RegistrationTranslation |
    SellToUsTranslation |
    ShareItemTranslation |
    ShareWishlistTranslation |
    TradeRequestTranslation |
    TradeRequestDelaerTranslation;
    

