export interface IStatusPage {
  id: number;
  status: string;
  key: string;
  stock_number: string;
  title: string;
  entity_id: string;
}

export interface IStatusPageResponse {
  data: IStatusPage[];
  current_page: number;
  total: number;
}

export interface IStatusColumnItem {
  name: string;
  key: string;
  sortOrder: 'ascend' | 'descend' | '';
}
