export const enum RouterLinks {
    SIGN_IN = '/auth/sign-in',
    AUTH = 'auth',
    NOT_FOUND = 'not-found',
    DASHBOARD = '/dashboard',
    WEB_PAGES = '/dashboard/web-pages',
    ARTISTS_LIST = '/dashboard/settings/artists',
    NEW_ARTIST = 'dashboard/settings/artists/create',
    CATEGORIES_LIST = '/dashboard/settings/categories/list',
    CATEGORY_DETAILS = 'dashboard/settings/categories/details',
    VOUCHERS = '/dashboard/ecommerce/vouchers',
    STOCKS_LIST = 'dashboard/stock/stock-list',
    STOCK_DETAILS = 'dashboard/stock/stock-details',
    BLOGS = 'dashboard/web-pages/blogs',
    BLOG_DETAILS = 'dashboard/web-pages/blogs/create',
    EVENTS = 'dashboard/web-pages/events',
    EVENT_DETAILS = 'dashboard/web-pages/events/create',
}
