import { IMatterPort } from '@models/utils/matterport';
import { IArtist, IStockItem } from '..';
import { IImage } from '../common-models/image';

export interface IExhibition {
    archived: number;
    cart_button_type: number;
    date_end: string;
    date_published: string;
    date_start: string;
    exhibit_description: string;
    exhibit_description_json: { [key: string]: string };
    exhibit_id: string;
    exhibit_subtitle: string;
    exhibit_subtitle_json: { [key: string]: string };
    exhibit_tag: string | string[];
    exhibit_tag_json: { [key: string]: any };
    exhibit_title: string;
    exhibit_title_json: { [key: string]: string };
    exhibit_type: string;
    image: IImage;
    location_id: string;
    location_text: string;
    location_text_json: { [key: string]: string };
    primary_image_id: string;
    registration_required: number;
    template_id: string;
    template_json: string;
    visible: number;
    stocks: IStockItem[];
    artists: IArtist[];
    matterport_links: IMatterPort[];
    description_json: { [key: string]: any };
    keywords_json: { [key: string]: any };
}

