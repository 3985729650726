import { ICategoryBox } from './category-box';

export interface ICategoryBoxesWithWebSettings {
    category_boxes: ICategoryBox[];
    title: { [key: string]: string };
    homepage_hide_category_boxes: string;
    label_position_categories: string;
    colour_category_images_overlay_background: string;
    colour_category_images_overlay_text: string;
}
