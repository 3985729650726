export interface ISocialMediaRequest {
  social_page_facebook: string;
  social_page_instagram: string;
  social_page_twitter: string;
  social_page_linkedin: string;
  social_page_pinterest: string;
  social_page_youtube: string;
  social_facebook_pixele: string;
  social_twitter_tag: string;
  social_linkedin_insight_tag: string;
  social_pinterest_tag: string;
}
