export interface ISalesOrdersAnalytics {
  discount_vouchers: ISalesOrdersAnalyticsItem;
  orders: ISalesOrdersAnalyticsItem;
  value: ISalesOrdersAnalyticsItem
}

 interface ISalesOrdersAnalyticsItem {
  daily: number;
  monthly: number;
  total: number;
  weekly: number;
}
