import { IImage } from '@models/common-models/image';
import { IAttributes } from '..';

export interface IAttributesGroup {
  attribute_group_desc_json: { [key: string]: string };
  attribute_group_id: string;
  attribute_group_name_json: { [key: string]: string };
  attributes_count?: number;
  image: IImage;
  id: string,
  attributes?: IAttributes[],
  stock_attributes?: IAttributes[],
  visible: number;
  sort_order: number;
  attributes_groups: IAttributes[];
  web_visible: boolean | number;
  web_selectable: boolean | number;
  attribute_group_web_visible: boolean | number;
  attribute_group_web_selectable: boolean | number;
}
