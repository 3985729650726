export interface IEnquiry {
  email: string;
  company_name: string;
  enquiry_id: string;
  firstname: string;
  lastname: string;
  message: string;
  phone: string;
  timestamp: string;
  images: any[];
  option_prices_sale: number;
  stock_number: string;
  enquiry_code: number;
  site_url?: string;
  stock: {
    title: string;
    price_retail: number;
    price_sale: number | boolean;
    price_sale_visible: number;
    price_visible: number;
    permalink: string;
    main_image: {};
  };
}
