export interface IAttributeTags {
  // archived: number;
  // permalink: string;
  // pivot: { [key : string] : string};
  sort_order: number;
  // sync_key: string;
  tag: string;
  tag_id: string;
  tag_json: { [key : string] : string};
  attributes_count: number;
}

