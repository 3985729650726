import { ITaxJurisdiction } from './jurisdiction';

export interface IUpdateTaxSettings {
    vat_or_tax_label: string;
    declaration: { [key: string]: string };
    jurisdictions: ITaxJurisdiction[];
    vat_tax_status: string;
    tax_service_config: string;
    name?: string;
    position?: string;
    company?: string;
    reason?: string;
    updated_at?: string;
}
