export interface ISalesAnalyticsByCountry {
  success: boolean
  data: ISalesAnalyticsByCountryList[]
}

export interface ISalesAnalyticsByCountryList {
  count?: number;
  country_name: string;
  total: number;
}
