import { IImage } from '@models/common-models/image';

export interface ICategoryBox {
    id: number;
    title: string;
    title_json: { [key: string]: string };
    subtitle: string;
    visible: boolean;
    pending?: boolean;
    class: string;
    img_url: string;
    images?: IImage[];
    image?: File;
    url: string;
}
