import {IAttributesGroup, IAttributeTags, IImage} from '@models/index';

export interface IAttributes {
  id?: string;
  checked: boolean;
  // archived: number;
  // attribute_desc: string;
  attribute_desc_json: { [key : string] : string};
  attribute_group_id : string;
  attribute_id: string;
  // attribute_name: string;
  attribute_name_json: { [key : string] : string};
  // image_id: string
  attribute_group: IAttributesGroup,
  // permalink: string
  sort_order: number;
  // sync_key: string
  // sync_last: string;
  tags: IAttributeTags[];
  image: IImage;
  retail: string;
  sale: string;
}
