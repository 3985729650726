import { IWebSetting } from '../common-models/web-setting';
import { ITaxJurisdiction } from './jurisdiction';

export interface ITaxSettings {
    declaration: IWebSetting;
    jurisdictions: ITaxJurisdiction[];
    vat_or_tax_label: IWebSetting;
    vat_tax_status: IWebSetting;
    tax_service_config: IWebSetting;
    name?: string;
    position?: string;
    company?: string;
    reason?: string;
    updated_at?: string;
}
