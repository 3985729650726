export interface IEmailTemplateCommon<T> {
    customized_template_json: { [key: string]: T };
    customized_template_parts: any;
    customized_template_parts_json: any[];
    default_email: number;
    default_template_json: { [key: string]: T };
    default_template_parts: any;
    default_template_parts_json: [];
    email_template_id: string;
    email_template_name: string;
    email_template_name_json: { [key: string]: string };
    email_template_type: string;
}
