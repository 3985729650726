export interface IContacts {
  firstname: string;
  lastname: string;
  email: string;
  type: number;
  timestamp_created: string;
  contact_uuid: string;
  company_name: string;
}

export interface IContactsResponse {
  data: IContacts[];
  current_page: number;
  total: number;
  per_page: number;
}

export interface ApiContactResponse<T> {
  contacts: T;
}
