export interface DefaultModalInputData {
  title?: {
    value: string,
    doNotTranslate?: true,
    translateProperties?: {
      [key: string]: string
    }
  };
  desc?: {
    value: string,
    doNotTranslate?: true,
    translateProperties?: {
      [key: string]: string
    }
  };
  /**  0 is a dialog with two button */
  template: 0 | 1;
  continue?: {
    name?: string,
    doNotTranslate?: true,
    translateProperties?: {
      [key: string]: string
    }
    color?: string;
    class?: string
  };
  cancel?: {
    name?: string,
    doNotTranslate?: true,
    translateProperties?: {
      [key: string]: string
    }
    color?: string;
    class?: string
  };
}
