import { IPaymentsKeys } from './payments-keys';

export interface IPaymentType {
  image: string | File;
  image_url: string;
  default_image_url: string;
  imagePreview: string;
  live_mode: number;
  min_limit: number;
  max_limit: number;
  name: string;
  type: string;
  api_url: string;
  keys: IPaymentsKeys;
  active: number
}
