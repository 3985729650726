export interface IStandardSettings {
    option_artists_page: string;
    option_blog_page: string;
    option_events_page: string;
    option_latest_arrivals_page: string;
    option_homepage_menu_link: string;
    titles_style_list_view: string;
    homepage_stock_widget: string;
    show_stocknumber_ownref: string;
    latest_arrivals_qty: string;
    artists_display: string;
    dimensions_inches_feet: string;
    list_view_text_position: string;
    sort_artists_list: string;
    prices_hide_all: string;
    show_share_facebook_button: string;
    show_share_twitter_button: string;
    show_share_pinterest_button: string;
    show_share_friend_button: string;
    image_resolutions: string;
}
