import { EImageTypes } from '@models/utils/utils';

export interface IImage {
    image_id: string;
    archived: number;
    image_name: string;
    image_url: string;
    list_url: string;
    thumb_url: string;
    parent_id: string;
    image_visible: number;
    main_image: number;
    permalink: string;
    alt_text: string;
    position: number;
    button_text: string;
    additional_text: string;
    image_type: EImageTypes;
    sync_key: string;
    sync_last: string;
}
