export interface IIntegrationStockResponse {
  data: T;
  main_image: string;
}

interface T {
  has: number;
  is_logged_in: boolean;
  status: string;
  updated_at: string;
}
