export interface IMenuOrders {
  text_active_color: string;
  text_color: string;
  text_hover_color: string;
  name?: string | { [key: string]: string };
  name_json: { [key: string]: string };
  url: string;
  items: ISubMenuOrders[];
  visible: number;
  type: string;
}

interface ISubMenuOrders {
  name_json: { [key: string]: string };
  visible: number;
}

export interface IMenuOrderItem {
  name: string;
  data: IMenuOrders[];
}
