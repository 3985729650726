export interface IDynamicsItems {
    title: string;
    general_description: string;
    dynamicItems: IDynamicsItem[];
    deleted_urls: string[];
}

export interface IDynamicsItem {
    title: string;
    description: string[] | string;
    image: File | string;
    image_preview?: string;
}
