export interface IPagesMenu {
  page_title_about: { [key: string]: string };
  page_title_faq: { [key: string]: string };
  page_title_artists: { [key: string]: string };
  page_title_archive: { [key: string]: string };
  page_title_contact: { [key: string]: string };
  page_title_blog: { [key: string]: string };
  page_title_featured: { [key: string]: string };
  page_title_events: { [key: string]: string };
  page_title_latest: { [key: string]: string };
  page_title_newsletter_menu: { [key: string]: string };
  page_title_newsletter_subtitle_footer: { [key: string]: string };
  page_title_newsletter_subtitle: { [key: string]: string };
  page_title_stock: { [key: string]: string };
  page_title_newsletter_title: { [key: string]: string };
  page_title_view_all: { [key: string]: string };
  page_title_press: { [key: string]: string };
  page_title_catalogues: { [key: string]: string };
  page_title_exhibitions: { [key: string]: string };
  page_title_customer_services: { [key: string]: string };
  page_title_artists_update_on_menu: number;
  page_title_archive_update_on_menu: number;
  page_title_blog_update_on_menu: number;
  page_title_featured_update_on_menu: number;
  page_title_events_update_on_menu: number;
  page_title_latest_update_on_menu: number;
  page_title_stock_update_on_menu: number;
  page_title_view_all_update_on_menu: number;
  page_title_press_update_on_menu: number;
  page_title_catalogues_update_on_menu: number;
  page_title_exhibitions_update_on_menu: number;
}
