export const enum LoadingNames {
    IMAGE_LOADING_FROM_URL = 'Image Upload Loading From Url',
    IMAGE_LOADING = 'Image Upload Loading',
    EVENTS_FILE_NAME_CHECKING = 'Events File Name Checking',

    // Stocks
    STOCK_CONSTANTS_LOADING = 'Stock Constants Loading',
    STOCK_ADD_LOCATION = 'Stock Add Location',
    STOCK_GET_STOCKS = 'Stock Get Stocks',

    // Blogs
    SAVE_BLOG = 'Save Blog Loading',

    // Events
    SAVE_EVENT = 'Save Event Loading',

    // Press
    SAVE_PRESS = 'Save Press Loading',

    // CATALOGUE
    CATALOGUE_GET_STOCKS = 'Get Stocks Loading',

    // EXHIBITION
    EXHIBITION_GET_STOCKS = 'Get Exhibition Stocks Loading',

    // GENERAL
    GENERAL_GET_STOCKS = 'Get General Stocks Loading',

    // Website Languages
    GET_WEBSITE_LANGUAGES = 'Get Website Languages',

    // Website Static Texts
    WEBSITE_STATIC_TEXTS = 'Webiste Static Texts',
    WEBSITE_STATIC_TEXTS_RESET = 'Webiste Static Texts Reset',

    // Newsletters 
    NEWSLETTERS_UPDATE = 'Newsletter Update',

    // Api Keys
    API_KEYS_SAVE = 'Api Keys Save',

    // Tax Settings
    TAX_SETTINGS_SAVE = 'Tax Settings Save',
    CREATE_TAX_ACCOUNT = 'Create Tax Account'
}

