import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IImageDeleteRequest, IImageUploadResponse, IStockImageUploadResponse } from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor(private _httpClient: HttpClient) { }

    public uploadImage(image: FormData) {
        return this._httpClient.post<IImageUploadResponse | IStockImageUploadResponse>('upload', image);
    }

    public deleteImage(data: IImageDeleteRequest) {
        return this._httpClient.post<void>('delete-image', data);
    }

    public downloadImageAsBlob(url: string) {
        return this._httpClient.get(url, { responseType: 'blob' as 'json', });
    }
}
