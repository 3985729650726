export interface IGeneral {
   company_email_general: string;
   company_email_sales: string;
   domains: string[];
   show_website_maintenance_page: string;
   subscription_popup_image_url: string;
   company_name: { [key: string]: string };
   print_pdf_footer: { [key: string]: string };
   copyright_message: { [key: string]: string };
   design_phone_number_in_header: { [key: string]: string };
   company_email_cc_sales: string[];
   company_email_bcc_sales: string[];
   company_email_cc_general: string[];
   company_email_bcc_general: string[];
}
