  export interface IDevice {
	label: string;
	value: string;
	checked?: boolean;
	disabled?: boolean;
}

export interface IIndustry {
	label: string;
	value: string;
	checked?: boolean;
}

export interface ITakePayment {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	notes: string;
	postcode: string;
	company: string;
	address: string;
	Devices: IDevice[] | string;
	Industry: IIndustry[] | string;
  otherInput?: string;
}
