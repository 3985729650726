export interface ILocationItem {
    address_1: string;
    address_2: string;
    address_3: string;
    append_message: number;
    archived: number;
    city: string;
    country_code: string;
    external_name: string;
    internal_name: string;
    location_id: string;
    phone: string;
    state: string;
    sync_key: string;
    sync_last: string;
    town: string;
    zip: string;
    show_append_message: boolean;
}
