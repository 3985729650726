export interface ITradeRequest {
  contact_uuid: string;
  firstname: string;
  lastname: string;
  company_name: string;
  email: string;
  trade_request: {
    contact_uuid: number;
    id: number;
    status: number;
  };
}

export interface ITradeRequestResponse {
  data: ITradeRequest[];
  current_page: string;
  per_page: string;
  total: string;
}
