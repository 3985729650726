export interface IVoucher {
  archived?: number;
  sync_key?: string;
  sync_last?: string;
  usage_count: number;
  usage_max: number;
  voucher_active: number;
  voucher_amount: number;
  voucher_code: string;
  voucher_end_date: string;
  voucher_id?: string;
  voucher_minimum_order_value: number;
  voucher_name: string;
  voucher_qrcode?: null;
  voucher_start_date: string;
  voucher_type: string;
}

export interface IVoucherGetCode {
  voucher_code: string;
}

export interface IVoucherSetting {
  voucher_allow_on_sale_price: string;
}
