export interface ILoginRequest {
    company_name: string;
    email: string;
    password: string;
}

export interface ILoginResponse {
    success: boolean;
    token: string;
    'c-id': string;
    company_name: string;
    'c-route': string;
    theme: string;
    business_manager_old: string;
}
