export interface INewsletters {
    mailchimp: INewsletterItem[];
}

export interface INewsletterItem {
    api_key: string;
    lists: INewsletterListItem[];
}

export interface INewsletterListItem {
    title: { [key: string]: string };
    title_json: { [key: string]: string };
    list_id: string;
    visible: number;
}
