export interface INewExhibition {
    title: { [key: string]: string };
    subtitle: { [key: string]: string };
    published_date: string;
    visible: number;
    start_date: string;
    end_date: string;
    image: File;
    type: 'public' | 'private';
    tags: { [key: string]: string };
    description: { [key: string]: string };
    location_id: string;
    location_text: { [key: string]: string };
    artists: string[];
    stocks: string[];
    matterport_ids: string[];
    description_json: { [key: string]: string };
    keywords: { [key: string]: string };
}
