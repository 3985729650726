import { IImage } from '../common-models/image';

export interface IPress {
  visible: number;
  image: IImage
  press_media_date: string;
  press_media_html: string;
  press_media_html_json: { [key: string]: string };
  press_media_id: string;
  press_media_image: string;
  press_media_slug: string;
  press_media_title: string;
  press_media_title_json: { [key: string]: string };
  press_media_type: string;
  press_media_url: string;
  sync_key: string;
  sync_last: string;
  tag: string;
  tag_json: { [key: string]: string };
  description_json: { [key: string]: string };
  keywords_json: { [key: string]: string };
}

export interface IPostPress {
  press_media_date: string;
  press_media_html: { [key: string]: string };
  press_media_image: string;
  press_media_title: { [key: string]: string };
  press_media_url: string;
  tag: { [key: string]: string };
  visible: number;
  press_media_type: string;
  keywords: { [key: string]: string };
  description: { [key: string]: string };
}
