export interface IIntegrations {
  antiques: IIntegrationItems;
  commerce: IIntegrationItems;
  conteprorary_art: IIntegrationItems;
  social: IIntegrationItems;
}

export interface IIntegrationItems {
  id: number;
  key: string;
  license: number;
  login: string;
  name: string;
  password: string;
  type: string;
}
