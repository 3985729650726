import { IEmailTemplateEnquiry } from '../enquiry/enquiry';
import { IEmailTemplateForgotPassword } from '../forgot-password/forgot-password';
import { IEmailTemplateOrderConfirmation } from '../order-confirmation/order-confirmation';
import { IEmailTemplateRegistration } from '../registration/registration';
import { ShareWishlist } from '../share-wishlist/share-wishlist';
import { IEmailTemplateTradeRequestDealer } from '../trade-request-dealer/trade-request-dealer';
import { IEmailTemplateTradeRequest } from '../trade-request/trade-request';
import { IEmailTemplateSellToUs } from './../sell-to-us/sell-to-us';

export type EmailTemplate =
    IEmailTemplateRegistration |
    ShareWishlist |
    IEmailTemplateOrderConfirmation |
    IEmailTemplateForgotPassword |
    IEmailTemplateSellToUs |
    IEmailTemplateTradeRequestDealer |
    IEmailTemplateEnquiry |
    IEmailTemplateTradeRequest;
