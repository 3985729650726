export interface INewCatalogue {
    title: { [key: string]: string };
    published_date: string;
    visible: number;
    image?: File;
    show_artist_name: number;
    description?: { [key: string]: string };
    subtitle?: { [key: string]: string };
    stocks: string[];
    stock_image?: string;
    _method?: 'put';
    description_json: { [key: string]: string };
    keywords: { [key: string]: string };
}
