export interface IContactsAnalytics {
    retail_contacts: IContactAnalyticsItem | number;
    trade_contacts: IContactAnalyticsItem | number;
}

interface IContactAnalyticsItem {
    daily: number;
    last_month: number;
    last_week: number;
    last_year: number;
    monthly: number;
    total: number;
    weekly: number;
    yearly: number;
    yesterday: number;
}
