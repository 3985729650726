import { EListViewType } from '@enums/widgets';
import { IListViewWidget } from './list-view';
import { IListMenuWidget } from './menu-list.-widget';

export interface IListViewWidgetPreview {
    type: EListViewType;
    image: string;
    name: string;
    description?: string;
    data: IListViewWidget | IListMenuWidget | [];
}
