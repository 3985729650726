export interface IOrders {
  order_number: number;
  timestamp: string;
  status: string;
  order_uuid: string;
  json_obj: {
    order_total: number;
    items: [];
    contact: {
      firstname: string;
      lastname: string;
    },
    seller: {
      currency_sign: string;
    }
  };
}



