export interface IStockAnalytics {
    available_items: {
        total: number;
        website_visible: number;
        available_to_buy: number;
    };
    sold_items: {
        total: number;
        website_visible: number;
    },
    reserved_items: {
        total: number;
        website_visible: number;
    },
    out_of_stock_items: {
        total: number;
        website_visible: number;
    }
}
