export interface ISideNavItem {
    label: string;
    routerLink: string;
    icon: string;
    access: string[];
}

export interface ISubNavigationItem {
    label: string;
    routerLink: string;
    image?: string;
    access: string[];
    hide?: boolean;
}
