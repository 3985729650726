import { CountryTaxType } from '@enums/index';
import { ILanguage } from '../languages/language';

export interface ISettings {
    date_format: string;
    time_format: string;
    country: string;
    site_url: string;
    country_tax_type: CountryTaxType;
    vat_tax_status: string;
    business_manager_old: string;
    tax_credentials: string;
    languages: ILanguage[];
    next_stock_number: number;
    option_multilingual_website: string;
    licence_enhancements_customer: string;
    option_prices_trade: string;
    licence_enhancements_unlimited: string;
    stocks_count: number;
    currency: string;
    popup_image_uploaded: string;
}
