import { VALID_HEX_COLOR, VALID_RGBA_COLOR } from '@constants/index';

export class ColorsHelper {

    static isValidHexColor(color: string): boolean {
        return VALID_HEX_COLOR.test(color);
    }

    static isValidRGBAColor(color: string): boolean {
        return VALID_RGBA_COLOR.test(color);
    }

    static generateRandomColor(): string {
        const x = Math.round(0xffffff * Math.random()).toString(16);
        const y = (6 - x.length);
        const z = '000000';
        const z1 = z.substring(0, y);
        return '#' + z1 + x;
    }
}
