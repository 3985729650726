export interface IAddOnSettings {
  licence_enhancements_customer: string;
  licence_enhancements_international: string;
  licence_enhancements_salesmarketing: string;
  licence_enhancements_unlimited: string;
  licence_enhancements_viewing: string;
  featured_items_qty: string;
  option_sell_with_us: string;
  show_stock_sold_items_in_search_menu: string;
  option_advanced_search: string;
  option_catalogues_collections: string;
  option_compare_page: string;
  option_faq_page: string;
  option_featured_items_page: string;
  option_filter_items: string;
  option_hover_second_image: string;
  option_instagram_page: string;
  option_multiple_newsletters: string;
  option_popup_homepage_subscription: string;
  option_popups: string;
  option_privates_galleries: string;
  option_related_items: string;
  option_shipping_price: string;
  option_stock_item_attributes: string;
  option_stock_item_videos: string;
  option_view_on_a_wall: string;
  option_wishlists_page: string;
  show_stock_sold_items: string;
  language: string;
  option_multilingual_website: string;
  option_multilingual_website_languages: string;
  social_instagram_access_token: string;
  social_facebook_user_id: string;
  option_press_page: string;
  option_reserved_items: string;
  show_stock_items_on_reserve: string;
  option_abandoned_cart: string;
  option_prices_trade: string;
  option_prices_sale: string;
  option_promotion_codes: string;
  option_personalisation: string;
  option_whatsapp: string;
  whatsapp_number: string;
  option_live_chat: string;
  live_chat_app_id: string;
  option_bank_payments: string;
  option_paypal: string;
  option_exhibitions_page: string;
  option_currency_conversion: string;
  view_art_on_wall: string;
  matterport_videos: string;
  licence_enhancements_AR3D: string;
  option_search: string;
}

