export interface IArtistStock {
    image_id: string;
    image_url: string;
    parent_id: string;
    status: string;
    stock_number_own_ref: string;
    title: string;
    stock_number: string;
    stock_id: string;
}
