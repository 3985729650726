export interface IEmailTemplateEnquiry {
  subject: string;
  header_text: string;
  name_phrase: string;
  phone_phrase: string;
  item_phrase: string;
  message_phrase: string;
  email_address_phrase: string;
  date_and_time_phrase: string;
  company_phrase: string;
  email_has_been_sent_phrase: string;
}
