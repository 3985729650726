
interface IWebsiteLanguge {
    code: string;
    default: number;
    active: number;
}

export interface IWebsiteLanguageUpdate {
    data: {
        multilingual: number;
        languages: IWebsiteLanguge[]
    };
}
