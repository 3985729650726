export interface IContactDetail {
  firstname: string;
  lastname: string;
  email: string;
  type: number;
  timestamp_created: string;
  contact_uuid: string;
  company_name: string;
  phone: string;
  mobile: string;
  vat_address: string;
  vat_company: string;
  vat_country: string;
  vat_number: string;
  source: string;
  addresses: IContactAddress[];
}

export interface IContactDetailResponse {
  contact: IContactDetail;
}



export interface IContactAddress {
  address_uuid: string;
  contact_uuid: string;
  address_reference: string;
  address_1: string;
  address_2: string;
  address_3: string;
  town: string;
  city: string;
  state: string;
  zip: string;
  country_code: string;
  note: string;
  company_name: string;
  contact_name: string;
  contact_phone: string;
  timestamp: string;
  sync_key?: any;
  sync_last?: any;
  is_billing: number;
  is_shipping: number;
}

