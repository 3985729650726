import { Observable, Observer } from 'rxjs';

export class FileHelper {

  /**
   * download the file with provided filename
   *
   * @param data the file content in arrayBuffer format
   * @param filename download file name
   *
   * @return `void`
   */
  static download(data: ArrayBuffer, filename: string) {
    // Use the URL object to create a temporary URL

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.download = filename;
    link.href = url;
    link.click();
  }

  /**
   * transfer file to base64
   *
   * @param file which should be transformed
   *
   * @return `Promise<string>`
   */
  static toBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject('');
    });
  }

  static getCanvasFromImageUrl(url: string) {
    return new Observable((observer: Observer<HTMLCanvasElement>) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.setAttribute('src', url);
      if (!img.complete) {
        img.onload = () => {
          observer.next(FileHelper.getCanvasFromImg(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(FileHelper.getCanvasFromImg(img));
        observer.complete();
      }
    });
  }

  static getCanvasFromImg(img: HTMLImageElement): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas;
  }

  static dataURItoBlob(dataURI: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    }
    else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  static isFileImage(file: File) {
    return file.type.match(/image.*/);
  }

  static isFileXLSX(file: File) {
    return file.name.match(/(.+)+(.xlsx|.csv)$/);
  }

  static isFileVideo(file: File) {
    return file.name.match(/([a-zA-Z0-9\s_\\.()\-:])+(.mp4|.mov|.wmv|.avi|.webm|.gif)$/);
  }

  static isImageGif(name: string) {
    return name.match(/([a-zA-Z0-9\s_\\.\-:])+(.gif)$/);
  }

  static isUrlVideo(url: string): boolean {
    return /([a-zA-Z0-9\s_\\.\-:])+(.mp4|.mov|.wmv|.avi|.webm|.gif)$/.test(url);
  }

  static isFilePdf(file: File) {
    return file.type.toLowerCase() === 'application/pdf';
  }

  static checkFileSize(file: File, options: { maxSize: number, minSize: number }) {
    if ('minSize' in options && options.minSize > file.size) {
      return false;
    }
    return !('maxSize' in options && options.maxSize < file.size);
  }

  static isImageExtensions(file: string) {
    const slashIndex = file.indexOf('/');
    let type = '';
    for (let index = slashIndex + 1; index < file.length; index++) {
      type += file[index];
    }
    return ['jpg', 'jpeg', 'png', 'gif'].includes(type);
  }

  static async checkImageDimension(
    file: File,
    options: ImageValidationOptionsModel
  ): Promise<{ [P in keyof ImageValidationOptionsModel]: boolean } & { valid: boolean }> {
    let extensions = options?.extensions;
    if (!extensions || !extensions.length) {
      extensions = ['jpg', 'jpeg', 'png', 'gif'];
    }

    const validate: any = {
      valid: true
    };

    const image = new Image();
    image.src = await FileHelper.toBase64(file);
    return new Promise((resolve, reject) => {
      image.onload = () => {

        const height = image.height;
        const width = image.width;

        if ('height' in options && height !== options.height) {
          validate.height = true;
          validate.valid = false;
        }

        if ('width' in options && width !== options.width) {
          validate.width = true;
          validate.valid = false;
        }

        if ('maxHeight' in options && height > options.maxHeight) {
          validate.maxHeight = true;
          validate.valid = false;
        }

        if ('minHeight' in options && height < options.minHeight) {
          validate.minHeight = true;
          validate.valid = false;
        }

        if ('maxWidth' in options && width > options.maxWidth) {
          validate.maxWidth = true;
          validate.valid = false;
        }

        if ('minWidth' in options && width < options.minWidth) {
          validate.minWidth = true;
          validate.valid = false;
        }
        return resolve(validate);
      };
    });
  }
}

export interface ImageValidationOptionsModel {
  maxWidth?: number;
  maxHeight?: number;
  minWidth?: number;
  minHeight?: number;
  width?: number;
  height?: number;
  extensions?: string[];
}
